import React, { useState, useEffect } from 'react' 
import { Tab, Nav, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPepperHot } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import Item from './Item'
import SETTINGS from '../constant'

function Menu(props) {
    const pepper = <FontAwesomeIcon icon={faPepperHot} />

    const [items, setItems] = useState([]);

    useEffect(() => {
        const fetchData  = async() => {
            const result = await axios(SETTINGS.BASE_URL + 'api/items');
            setItems(result.data);
        }

        fetchData();
    }, []);

    const listItems = () => {
        const categoryItems = items.filter(item => {
            return item.category == 'emporter'
        });

        const leftItems = categoryItems.filter((item, i) => {
            return i%2 == 0
        });
        const rightItems = categoryItems.filter((item, i) => {
            return i%2 == 1
        });

        return (
            <div className="row">
                <div className="col col-12 col-lg-6">
                    {leftItems.map(item => {
                        return <Item key={item.id} {...item} />
                    })}
                </div>
                <div className="col col-12 col-lg-6">
                    {rightItems.map(item => {
                        return <Item key={item.id} {...item} />
                    })}
                </div>
            </div>
        )
    };

    return ( 
        <div className="menu">
            <div className="container tab-content">
                <p className="text-center mb-4 exergue h5">
                    <em>Consultez la carte de nos plats à emporter. Livraison possible sur Rennes pendant le confinement.<br />Nous appeler pour plus de renseignements.</em><br />
                </p>
                
                {listItems()}
            </div>
        </div>
    )
}

export default Menu;