import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Menu from '../Components/Menu';
import bkgMezzes from '../Images/bkg-mezzes.jpg';
import bkgVegetariens from '../Images/bkg-vegetariens.jpg';
import bkgGrillades from '../Images/bkg-grillades.jpg';
import bkgDesserts from '../Images/bkg-desserts.jpg';
import bkgVins from '../Images/bkg-vins.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons'

function Carte(props) {
    const { category } = (props.match.params.category !== undefined) ? props.match.params : {category: 'mezzes'};
    const phone = <FontAwesomeIcon icon={faPhoneSquareAlt} mask={['fas']} />
    const [items, setItems] = useState([
        'a'
    ]);
    if (category == 'mezzes')
        var bkg = bkgMezzes;
    else if (category == 'vegetariens')
        var bkg = bkgVegetariens;
    else if (category == 'grillades')
        var bkg = bkgGrillades;
    else if (category == 'desserts')
        var bkg = bkgDesserts;
    else if (category == 'plats')
        var bkg = bkgMezzes;
    else 
        var bkg = bkgVins;

    return (
        <section id="carte" className="page">
            <div className="background-image" style={{backgroundImage: `url(${bkg})`}}></div>

            <div className="container">
                <div className="page-title">
                    <span className="topline">Découvrez</span>
                    <h2>Notre Menu</h2>
                    <Link to="/contact" className="btn mt-3" id="commande-btn"> 
                        { phone }&nbsp;
                        Réservez : <b>02.99.63.51.91</b>
                    </Link>
                </div>

                <Menu {...props} />
            </div>
        </section>
    )
}

export default Carte;