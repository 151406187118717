import React from 'react';
import imageRight from '../Images/traiteur.jpg';
import imageLeft from '../Images/traiteur-2.jpg';

function Epicerie() {
    return (
        <section id="demarche" className="page">
            <div className="container">
                <div className="row">
                    <div className="col col-12 col-lg-6">
                        <figure className="illustration">
                            <img src={imageLeft} alt="" />
                            <img src={imageRight} alt="" />
                        </figure>
                        <div className="row">
                            <div className="col col-12 col-lg-8 offset-lg-4 container-card">
                                <div className="card">
                                    <h3>Notre plus</h3>
                                    <p>
                                        Profitez de notre cuisine traditionnelle libannaise à votre domicile, grâce à notre service de livraison.<br />
                                        Nous consulter pour plus de détails.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-12 col-lg-6">
                        <div className="page-title">
                            <span className="topline">Découvrez notre</span>
                            <h2>Service traiteur</h2>
                        </div>

                        <div className="row page-content">
                            <div className="col-12 col-lg-8 offset-lg-4">
                                <p>
                                    Le restaurant Adonis n'est pas seulement un restaurant c'est aussi, le traiteur libanais à Rennes. En effet, le restaurant Adonis vous offre l'opportunité d'organiser, de préparer tous vos repas évènementiels, votre déjeuner, dîner, réception, banquet de mariage, anniversaire, repas de famille, tout repas de groupe, d'entreprise, de C.E etc. Bref, tous vos repas entre amis ou en famille.
                                </p>
                                <p>
                                    Le traiteur Adonis vous reçoit vous guide, vous accompagne tout au long de votre choix, assure vos commandes et les réalisent afin que votre événement se déroule de la manière la plus optimale qui soit.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Epicerie;