import React, { useState, useEffect } from 'react'
import { Tab, Nav, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import Item from './Item'
import SETTINGS from '../constant'

function Menu(props) {
    const { category } = (props.match.params.category !== undefined) ? props.match.params : {category: 'mezzes'};
    const [items, setItems] = useState([]);

    const setURL = (data) => {
        let url = '/la-carte/' + data;
        props.history.push(url);
    };

    useEffect(() => {
        const fetchData  = async() => {
            const result = await axios(SETTINGS.BASE_URL + 'api/items');
            setItems(result.data);
        }

        fetchData();
    }, []);

    const listItems = (cat) => {
        const categoryItems = items.filter(item => {
            return item.category == cat
        });

        const leftItems = categoryItems.filter((item, i) => {
            return i%2 == 0
        });
        const rightItems = categoryItems.filter((item, i) => {
            return i%2 == 1
        });

        if (categoryItems.length == 0)
            return (
                <p>Aucun élément disponible pour le moment.</p>
            );

        return (
            <div className="row">
                <div className="col col-12 col-lg-6">
                    {leftItems.map(item => {
                        return <Item key={item.id} {...item} />
                    })}
                </div>
                <div className="col col-12 col-lg-6">
                    {rightItems.map(item => {
                        return <Item key={item.id} {...item} />
                    })}
                </div>
            </div>
        )
    };

    return (
        <div className="menu">
        <Tab.Container id="menu" defaultActiveKey={category} onSelect={setURL}>
                <Row>
                    <Col sm={12}>
                    <Nav variant="pills" className="flex-row justify-content-center">
                        <Nav.Item>
                            <Nav.Link eventKey="mezzes">Mézzé chauds & froids</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="vegetariens">Mézzé végétariens</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="plats">Plats</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="grillades">Grillades</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="desserts">Desserts</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="vins">Vins</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    </Col>
                    <Col sm={12}>
                        <Tab.Content>
                            <Tab.Pane eventKey="mezzes">
                                <p className="text-center mb-4 exergue h5">
                                    <em>Mézzé libanais chauds et froids</em>
                                </p>
                                {listItems('mezzes')}
                            </Tab.Pane>
                            <Tab.Pane eventKey="vegetariens">
                                <p className="text-center mb-4 exergue h5">
                                    <em>Nos mézzé libanais végétariens</em><br />
                                </p>
                                {listItems('vegetariens')}
                            </Tab.Pane>
                            <Tab.Pane eventKey="plats">
                            <p className="text-center mb-4 exergue h5">
                                    <em>Notre sélection de plats individuels</em><br />
                                </p>
                                {listItems('plats')}
                            </Tab.Pane>
                            <Tab.Pane eventKey="grillades">
                            <p className="text-center mb-4 exergue h5">
                                    <em>Nos grillades libanaises</em><br />
                                </p>
                                {listItems('grillades')}
                            </Tab.Pane>
                            <Tab.Pane eventKey="desserts">
                                <p className="text-center mb-4 exergue h5">
                                    <em>Nos désserts</em><br />
                                </p>
                                {listItems('desserts')}
                            </Tab.Pane>
                            <Tab.Pane eventKey="vins">
                            <p className="text-center mb-4 exergue h5">
                                    <em>Nos vins libanais : blanc, rouge, rosé.</em><br />
                                    <small>À consommer avec modération</small>
                                </p>
                                {listItems('vins')}
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    )
}

export default Menu;
