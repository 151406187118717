import React from 'react';
import { Link } from 'react-router-dom'
import Moment from 'moment'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopyright } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faInstagram } from '@fortawesome/fontawesome-free-brands'

function Footer() {
    const today = new Moment();
    const copyright = <FontAwesomeIcon icon={faCopyright} mask={['far']} />
    const facebook = <FontAwesomeIcon icon={faFacebookF} mask={['far']} />
    const instagram = <FontAwesomeIcon icon={faInstagram} mask={['far']} />

    return (
        <div className="container py-3">
            <p className="text-center m-0">
                <a target="_blank" className="mr-3" href="https://www.facebook.com/Restaurant-Adonis-371102926277481/">{facebook} Facebook</a>
                <a target="_blank" className="mr-3" href="https://www.instagram.com/restaurant_adonis_/">{instagram} Instagram</a>
            
                {today.format('YYYY')}&nbsp;
                {copyright}&nbsp;
                Restaurant Adonis&nbsp;|&nbsp;
                <Link to="/mentions-legales">Mentions légales</Link>
            </p>
        </div>
    )
}

export default Footer;