import React, { useState, useEffect } from 'react' 
import { NavbarProps } from '../Interfaces/NavbarInterface' 
import { connect } from "react-redux"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons'
import * as actions from '../Actions' // On importe toutes les actions
import { withRouter } from 'react-router-dom'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import logo from '../Images/logo.png'
 
function Navigation(props: NavbarProps) {
    const phone = <FontAwesomeIcon icon={faPhoneSquareAlt} />
    
    return ( 
        <>
            <Navbar collapseOnSelect bg="dark" variant="dark" fixed="top" expand="lg" className="text-center">
                <div className="container">
                    <Navbar.Brand href="/" className={`mx-auto ${(props.match.path == '/') ? 'active' :''}`}>
                        <img src={logo} alt="Logo restaurant Adonis" className="logo" /> 
                    </Navbar.Brand>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav activeKey={props.location.pathname} className="mr-auto">
                            <Nav.Link href="/qui-sommes-nous" className="mx-2">Qui sommes-nous</Nav.Link>
                            <NavDropdown title="La carte" id="collasible-nav-dropdown" className="mx-2">
                                <NavDropdown.Item href="/la-carte/mezzes">Mézzé chauds & froids</NavDropdown.Item>
                                <NavDropdown.Item href="/la-carte/vegetariens">Mézzé végétariens</NavDropdown.Item>
                                <NavDropdown.Item href="/la-carte/plats">Plats individuels</NavDropdown.Item>
                                <NavDropdown.Item href="/la-carte/grillades">Grillades</NavDropdown.Item>
                                <NavDropdown.Item href="/la-carte/desserts">Désserts</NavDropdown.Item>
                                <NavDropdown.Item href="/la-carte/vins">Vins</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/a-emporter" className="mx-2">À emporter</Nav.Link>
                            <Nav.Link href="/traiteur-libannais" className="mx-2">Traiteur</Nav.Link>
                            <Nav.Link href="/contact" className="mx-2">Contact</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Brand href="tel:+33299635191" className={`mx-auto reservation-btn d-none d-sm-block ${(props.match.path == '/reservation') ? 'active' :''}`}>
                        {phone} Réservez: 02.99.63.51.91
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                </div>
            </Navbar>
        </>
    )
}

let routeredNavigation = withRouter(Navigation);

const mapStateToProps = state => {
    return {};
}

export default connect(mapStateToProps, actions)(routeredNavigation); // On connecte le composant à toutes les actions possibles