import { SET_NEWS } from "../Actions/ActionsTypes";

const initialState = {
    articles: null
};

export default function NewsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_NEWS:
            console.log('SET news', action);
            return {
                ...state, articles: [ ...action.payload ]
            }
        default:
            return state;
    }
}