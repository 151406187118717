import {PARSE_MESSAGE, SET_AUTHENTIFICATION} from "../Actions/ActionsTypes";

const initialState = {
    isLoggedIn: false,
    message: ""
};

export default function NavbarReducer(state = initialState, action) {
    switch(action.type) {
        case SET_AUTHENTIFICATION:
            return {
                isLoggedIn: action.payload
            };
        case PARSE_MESSAGE:
            return {
                ...state,
                message: action.payload
            }
        default:
            return state;
    }
}