import React, { useState } from 'react';
import bkg from '../Images/bkg-reservation.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons'
import Moment from 'moment'; 

function Contact(props) {
    const today = new Moment();
    const tomorrow = today.add(1, 'day');

    const [coords, setCoords] = useState({
        name: '',
        tel: '',
        email: '',
        date: '',
        nombre: '',
        horaire: ''
    });

    const handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;

        setCoords({
            ...coords,
            [name]: value
        })
    };

    const handleSubmit = (event) => {
        console.log('submit', event);
    }

    const phone = <FontAwesomeIcon icon={faPhoneSquareAlt} mask={['fas']} />

    return (
        <section id="contact" className="page">
            <div className="background-image" style={{backgroundImage: `url(${bkg})`}}></div>

            <div className="container">
                <div className="page-title">
                    <span className="topline">Du mardi au vendredi - 18h30 à 20h</span>
                    <h2>Réservez votre table</h2>
                    <a className="btn mt-3" id="reservation-btn" href="tel:+33299635191"> 
                        { phone }&nbsp;
                        Appelez-nous : <b>02.99.63.51.91</b>
                    </a>
                </div>

                <div className="row">
                    <div className="col col-12 col-lg-3 mt-4 bloc-left text-light">
                        <h2>Important</h2>
                        <p>
                            Notre restaurant reste ouvert pendant la période de crise sanitaire, uniquement sur les services de plats à emporter et de traiteur.<br />
                            Nous consulter pour plus d'informations.
                        </p>
                    </div>
                    <div className="col col-12 col-lg-6 bloc-center">
                        <h2 className="mb-4">Informations pour réservation</h2>
                        <form>
                            <div className="row">
                                <div className="col col-12 col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="name">Nom :</label>
                                        <input type="text" className="form-control form-control-lg" name="name" value={coords.name} onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">Téléhpone :</label>
                                        <input type="text" className="form-control form-control-lg" name="tel" value={coords.tel} onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">Date :</label>
                                        <input 
                                            type="date" 
                                            className="form-control form-control-lg" 
                                            min={tomorrow.format('YYYY-MM-DD')}
                                            name="date" 
                                            value={coords.date} 
                                            onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="col col-12 col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="name">Email :</label>
                                        <input type="text" className="form-control form-control-lg" name="email" value={coords.email} onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">Nombre de personnes :</label>
                                        <input type="text" className="form-control form-control-lg" name="nombre" value={coords.nombre} onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">Horaire :</label>
                                        <input type="text" className="form-control form-control-lg" name="horaire" value={coords.horaire} onChange={handleChange} />
                                    </div>
                                </div>
                                <p className="text-center mt-4 w-100">
                                    <input className="btn" type="submit" onSubmit={handleSubmit} value="Réserver" />
                                </p>
                            </div>
                        </form>
                    </div>
                    <div className="col col-12 col-lg-3 mt-4 bloc-right text-light">
                        <h2>Jours / horaires d'ouverture</h2>
                        <div className="d-flex day align-items-center">
                            <span className="label">
                                <span>Mardi</span>
                            </span>
                            <div className="horaire">
                                12h00 - 13h45<br />
                                19h00 - 22h30
                            </div>
                        </div>
                        <div className="d-flex day align-items-center">
                            <span className="label">
                                <span>Mercredi</span>
                            </span>
                            <div className="horaire">
                                12h00 - 13h45<br />
                                19h00 - 22h30
                            </div>
                        </div>
                        <div className="d-flex day align-items-center">
                            <span className="label">
                                <span>Jeudi</span>
                            </span>
                            <div className="horaire">
                                12h00 - 13h45<br />
                                19h00 - 22h30
                            </div>
                        </div>
                        <div className="d-flex day align-items-center">
                            <span className="label">
                                <span>Vendredi</span>
                            </span>
                            <div className="horaire">
                                12h00 - 13h45<br />
                                19h00 - 22h45
                            </div>
                        </div>
                        <div className="d-flex day align-items-center">
                            <span className="label">
                                <span>Samedi</span>
                            </span>
                            <div className="horaire">
                                12h00 - 13h45<br />
                                19h00 - 22h45
                            </div>
                        </div>
                        <div className="d-flex day align-items-center">
                            <span className="label">
                                <span>Dimanche</span>
                            </span>
                            <div className="horaire">
                                12h00 - 13h45<br />
                                19h00 - 22h30
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact;