import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import bkg from '../Images/bkg-actualites.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF } from '@fortawesome/fontawesome-free-brands'
import SETTINGS from '../constant';
import Moment from 'moment'; 
import 'moment/locale/fr';
import { FacebookShareButton, FacebookIcon } from 'react-share'

Moment.locale('fr')

function Actualite({match}) {
    const nl2br = require('react-nl2br');
    const id = match.params.id;
    const arrowCircleRight = <FontAwesomeIcon icon={faArrowCircleRight} mask={['fas']} />
    const back = <FontAwesomeIcon icon={faArrowLeft} mask={['fas']} />
    const facebook = <FontAwesomeIcon icon={faFacebookF} mask={['far']} />
    const { articles } = useSelector(state => state.news);
    const current = articles ? articles.filter(article => { console.log('article', article); return article.id == id})[0] : null;

    const renderDate = (date) => {
        if (date) {
            const oDate = new Moment(date);
            return oDate.format('DD MMMM YYYY').toLowerCase();
        }

        return '';
    }

    const renderVideo = (url) => {
        const regex = new RegExp('^(https?)?(:\/\/)?([\\w]+).([\\w]+)\/([\\w]*)$', 'gi');
        const matches = regex.exec(url);

        if (matches && matches[5] != undefined) {
            return <div className="container text-center mt-4">
                <iframe width="560" height="315" src={"https://www.youtube.com/embed/" + matches[5]} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
            </div>
        }

        return <></>
    }

    return (
        <section id="actualites" className="page">
            <div className="background-image" style={{backgroundImage: `url(${bkg})`}}></div>

            {(current == undefined || current == null) ?
                <div className="container">
                    <div className="inner-page text-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Chargement...</span>
                        </div>
                    </div>
                </div> :
                <div className="container">
                    <div className="inner-page">
                        <p className="text-right">
                            <Link className="btn" to="/">
                                {back} Retour
                            </Link>
                        </p>

                        <div className="mb-2">
                            <FacebookShareButton 
                                url={SETTINGS.SITE_BASE_URL + 'actualites/' + current.id}
                                quote={current.title}
                                hashtag="#adonis"
                                className={{}}>
                                <FacebookIcon size={24} />
                            </FacebookShareButton>

                            <small className="ml-2">Le {renderDate(current.date)}</small>
                        </div>

                        <h2 className="mb-3">{current.title}</h2>
                        
                        <p className="clearfix">
                            {(current.media != undefined && typeof current.media == 'string' && current.media.length > 0) &&
                                <img src={SETTINGS.BASE_URL + 'uploads/medias/actuality/' + current.media} className="rounded float-left actuality-illustration" />
                            }
                            {nl2br(current.content)}
                        </p>

                        {(current.video != undefined && current.video != null && typeof current.video == 'string' && current.video.length > 0) && 
                            renderVideo(current.video)
                        }
                    </div>
                </div>
            }
        </section>
    )
}

export default Actualite;