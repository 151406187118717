import React from 'react';

function Epicerie() {
    return (
        <section id="mentions" className="page">
            <div className="container">
                <div className="row">
                    <div className="col col-12 ">
                        <h1>Mentions légales</h1>
                        <p>
                            <strong>PERSONNE MORALE :</strong><br />
                            Raison sociale : Restaurant Adonis<br />
                            Siège Social : 24, RUE DE RENNES 35520 MELESSE<br />
                            Téléphone : 02 99 54 14 56<br />
                            Capital Social : 7 500 €<br />
                            N°Siret : 791 318 496 000 19
                        </p>
                        <p>
                            <strong>HEBERGEMENT :</strong><br />
                            Raison sociale : OVH<br />
                            SAS au capital de 500 K€<br />
                            RCS Roubaix à Tourcoing 424 761 419 00011<br />
                            Code APE 6202A<br />
                            N° TVA : FR 22 424 761 419<br />
                            Siège social : 2 rue Kellermann - 59100 Roubaix - France.
                        </p>
                        <p>
                            <strong>CREDIT PHOTOGRAPHIQUE :</strong><br />
                            Nos crédits photographiques sont la propriété de "Restaurant Adonis".<br />
                            Le présent site est la propriété de la société Restaurant Adonis.
                        </p>
                        <p>
                            <strong>ARTICLE 1 : OBJET</strong><br />
                            La présente notice a pour objet de définir les modalités selon lesquelles "Restaurant Adonis" met à  la disposition des internautes son Site Internet www.osoleiou.fr et les conditions selon lesquelles les internautes accèdent et utilisent ce Site Internet. Toute connexion au Site www.osoleiou.fr est subordonnée au respect de la présente notice légale que "Restaurant Adonis" se réserve de modifier ou de mettre à  jour à  tout moment.
                            L'accès et l'utilisation du Site www.osoleiou.fr entrainent votre adhésion à  la présente notice légale. Si vous n'étiez pas en accord avec les clauses énoncées ci-après, il vous est conseillé ne pas utiliser ce Site.
                        </p>
                        <p>
                            <strong>ARTICLE 2 : ACCES AU SITE</strong><br />
                            "Restaurant Adonis" s'efforce de maintenir accessible le Site, sans pour autant être tenue à  aucune obligation d'y parvenir. Il est précisé qu'à  des fins de maintenance, de mise à  jour, et pour toute autre raison notamment d'ordre technique, l'accès au Site pourra être interrompu. "Restaurant Adonis" n'est en aucun cas responsable de ces interruptions et des conséquences qui peuvent en découler pour l'internaute.
                        </p>
                        <p>
                            <strong>ARTICLE 3 : DROITS DE PROPRIETE INTELLECTUELLE</strong><br />
                            Les contenus (notamment données, bandes sons, informations, illustrations, logos, marques, etc.) qui apparaissent ou sont disponibles sur le site www.osoleiou.fr sont protégés au titre du droit d'auteur et autres droits de propriété intellectuelle et sont la propriété exclusive de leurs éditeurs respectifs. Toute copie, reproduction, représentation, adaptation, altération, modification, diffusion, intégrale ou partielle, du contenu du site de "Restaurant Adonis" en général qu'il lui appartienne ou à  un tiers, par quelque procédé que ce soit, est illicite à  l'exception d'une unique copie, sur un seul ordinateur et réservée à  l'usage exclusivement privé du copiste. Les éléments présentés dans ce site sont susceptibles de modification sans préavis et sont mis à  disposition sans aucune garantie d'aucune sorte, expresse ou tacite, ne peuvent donner lieu à  un quelconque droit à  dédommagement. Les informations et les images contenues dans le site Internet sont protégées par copyright à "Restaurant Adonis" ou copyright de ses partenaires.
                        </p>
                        <p>
                            <strong>ARTICLE 4 : UTILISATION DES DOCUMENTS</strong><br />
                            Aucun des documents provenant du site www.osoleiou.fr , ne peut être copié, reproduit, publié, téléchargé, posté, transmis ou distribué de quelque manière que ce soit, sauf dans le cas d'un simple enregistrement des documents sur micro-ordinateur pour votre utilisation personnelle et à  des fins non commerciales. Vous devez alors conserver intactes les mentions de propriété. L'altération et la modification de ces documents ou leur utilisation dans un autre but constitue une infraction aux droits de propriété de "Restaurant Adonis" ou de tiers.
                        </p>
                        <p>
                            <strong>ARTICLE 5 : COURRIERS ELECTRONIQUES</strong><br />
                            Pour correspondre avec "Restaurant Adonis" par courrier électronique, les internautes devront remplir les formulaires de correspondances électroniques proposés sur le site www.osoleiou.fr . Les réponses électroniques formulées par "Restaurant Adonis" ne pourront être assimilées ou ne pourront constituer la preuve de l'exercice d'une activité publicitaire, promotionnelle ou commerciale sur le territoire du pays de destination des réponses.
                        </p>
                        <p>
                            <strong>ARTICLE 6 : LIMITATION DE RESPONSABILITE</strong><br />
                            Les documents et informations diffusés sur www.osoleiou.fr sont fournis « en l'état » sans aucune garantie expresse ou tacite de quelque sorte que ce soit. "Restaurant Adonis" se réserve le droit de modifier ou de corriger le contenu de son site à  tout moment, sans préavis. "Restaurant Adonis" ne pourra être tenu pour responsable en cas de contamination des matériels informatiques des internautes résultant de la propagation d'un virus ou autres infections informatiques. Il appartient à  l'utilisateur de ce site de prendre toutes les mesures appropriées de façon à  protéger ses propres données et/ou logiciels de la contamination par des éventuels virus circulant sur le réseau Internet. En aucun cas "Restaurant Adonis", ses employés, ses fournisseurs, ou les partenaires mentionnés dans son site ne pourront être tenus responsables, au titre d'une action en responsabilité contractuelle, en responsabilité délictuelle ou de tout autre action, de tout dommage direct ou indirect, incident ou accessoire, ou de quelque nature qu'il soit ou de tout préjudice, notamment, de nature financier ou commercial, résultant de l'utilisation de son site ou d'une quelconque information obtenue sur son site.<br />
                            Le Site www.osoleiou.fr peut contenir des liens simples ou profonds vers des sites partenaires de "Restaurant Adonis" ou de tiers. "Restaurant Adonis" n'exerce aucun contrat sur ces sites et n'assure par conséquent, aucune responsabilité quant à la disponibilité de ces sites, leur contenu, publicité, produits et/ou services disponibles sur ou à  partir de ces sites. Ainsi, "Restaurant Adonis" ne sera aucunement responsable des dommages directs ou indirects pouvant survenir à  l'occasion de l'accès de l'internaute sur le site du partenaire ou de l'utilisation de ce Site, du non-respect des contenus et services à  toute réglementation ou toute atteinte aux droits de tiers. "Restaurant Adonis" décline toute responsabilité concernant les informations, les matériaux, les logiciels des sites reliés par lien hypertexte au Site www.osoleiou.fr .
                        </p>
                        <p>
                            <strong>ARTICLE 7 : COMPETENCE JURIDICTIONNELLE ET LOI APPLICABLE</strong><br />
                            Tout litige relatif au Site ou à  la présente notice légale, sera porté devant un tribunal de France sera régi et analysé selon la législation française sur le fond, indépendamment des règles de conflit de lois.
                            L'utilisation du Site www.osoleiou.fr signifie votre accord express à  l'application de la présente clause juridictionnelle. Dans le cas où l'une des dispositions de cette notice légale serait jugée illégale, nulle ou inapplicable pour quelque raison que ce soit, elle serait considérée comme ne faisant pas partie de cette notice légale et n'affecterait pas la validité ni l'application des autres dispositions.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Epicerie;