import React, { useState, useEffect } from 'react' 
import nl2br from 'react-nl2br'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPepperHot } from '@fortawesome/free-solid-svg-icons'

function Item(props) {
    const pepper = <FontAwesomeIcon icon={faPepperHot} />
    const hightlightClass = props.hightlight == 1 ? 'exergue' : '';
    const showPepper = (() => {
        const i = (props.pepper) ? parseInt(props.pepper) : 0;

        if (i == 0)
            return '';
        if (i == 1) 
            return <>
                {pepper} 
            </>
        if (i == 2) 
            return <>
                {pepper} 
                {pepper} 
            </>
        if (i == 3) 
        return <>
            {pepper} 
            {pepper} 
            {pepper} 
        </>
    });

    const showSubtitles = (() => {
        if (!props.subtitles || props.subtitles.trim() == '')
            return '';

        const subtitles = props.subtitles.split('\n');
    
        return subtitles.map((subtitle, i) => {
            const priceRegex = new RegExp(/([\d]{1,}(,[\d]{1,})?)€/gi);
            const matches = subtitle.match(priceRegex);
            
            const title = (matches) ? subtitle.replace(matches[0], '') : subtitle;
            const price = (matches) ? <span className="price">{matches[0]}</span> : '';
            
            return (
                <div key={i} className="d-flex align-items-center">
                    <span className="item-title">{subtitle}</span>
                    {price}
                </div>
            );
        });
    })

    return (
        <div className={`item ${hightlightClass}`}>
            <div className="d-flex text align-items-center">
                <h3 className="label">
                    <span>
                        {props.label}
                        {(props.small) ? <small>{props.small}</small> : ''}
                        {showPepper()}
                    </span>
                </h3>
                {
                    (props.price && props.price.trim() != '') ? 
                        <div className="price">
                            {props.price}
                        </div> :
                        ''
                }
            </div>
            {showSubtitles()}
            <div className="desc d-block">
                {nl2br(props.description)}
            </div>
        </div>
    )
}

export default Item;