import React, { useState, useEffect } from 'react';
import imageRight from '../Images/qui-sommes-nous.jpg';
import imageLeft from '../Images/qui-sommes-nous-2.jpg';

function Demarche() {
    return (
        <section id="quisommesnous" className="page">
            <div className="container">
                <div className="row">
                    <div className="col col-12 col-lg-6">
                        <figure className="illustration">
                            <img src={imageLeft} alt="" />
                            <img src={imageRight} alt="" />
                        </figure>
                        <div className="row">
                            <div className="col col-12 col-lg-8 offset-lg-4 container-card">
                                <div className="card">
                                    <h3>Adonis vous reçoit</h3>
                                    <ul>
                                        <li>Du mardi au dimanche, jours fériés inclus de 12h à 13h45 et de 19h à 22h30.</li>
                                        <li>Le vendredi et le samedi jusqu'à 22H45</li>
                                        <li>Ouvert le lundi sur réservation de groupe.</li>
                                    </ul>
                                    <p>
                                        Le restaurant libanais Adonis à Rennes est le lieu et l'espace idéal pour tous vos repas conviviaux de fêtes, en groupe, entre amis ou en famille.
                                    </p>
                                    <p>
                                        <strong>« Toutes nos viandes sont Halales »</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-12 col-lg-6">
                        <div className="page-title">
                            <h2>Qui sommes-nous</h2>
                            <span className="baseline">Découvrez notre restaurant</span>
                        </div>

                        <div className="row page-content">
                            <div className="col-12 col-lg-8 offset-lg-4">
                                <p>
                                    A deux pas de la place Saint Anne et du Palais des Congrès à Rennes (station Saint Anne), des parkings Hoche et des lices, et sur la partie pavée de la très célèbre et longue rue Saint Malo, se situe le restaurant libanais ainsi que le traiteur libanais connu sous l'enseigne restaurant et traiteur libanais Adonis.
                                </p>
                                <p>
                                    Façade de couleur de bordeaux éclairée avec trois lanternes qui indiquent et marquent les lieux.
                                </p>
                                <p>
                                    Le restaurant Adonis est une des plus vieille enseigne de la restauration libanaise à Rennes, situé au N°17 de la rue Saint Malo.<br />
                                    Le restaurant Adonis à Rennes est un restaurant typiquement et éxclusivement libanais, 
                                    c'est une adresse bien établie, raffinée et élégante à l'excellente cuisine savoureuse, généreuse, 
                                    fraîche, idéale pour un dépaysement total et pour découvrir en amoureux entre amis ou en famille, 
                                    pour céder à la tentation libanaise et qui présente l'avantage de la discrétion mélée à un accueil chaleureux, 
                                    convivial et des tarifs bien raisonnables avec un grand choix de menus: de mézzé (avec ou sans grillades), des mézzé végétariens, 
                                    végétaliens, des assiettes de grillades libanaises, des salades, entre 13 et 20€.
                                </p>
                                <p>
                                    Le restaurant Adonis à Rennes vous accueille sur 2 salles à décor et ambiance typiquement libanais.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Demarche;