import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Carousel } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'
import diapo from '../Images/carrousel-1.jpg'
import defaultBackground from '../Images/bkg-actu.jpg';
import imgQuisommesnous from '../Images/home-qui-sommes-nous.jpg'
import imgActualite from '../Images/home-actualite.jpg'
import imgCarte from '../Images/home-carte.jpg'
import imgEmporter from '../Images/home-a-emporter.jpg'
import imgTraiteur from '../Images/home-traiteur.jpg'

import SETTINGS from '../constant'
import Moment from 'moment'; 
import 'moment/locale/fr';


function Home() {
    const { articles } = useSelector(state => state.news);
    const arrowCircleRight = <FontAwesomeIcon icon={faArrowCircleRight} mask={['fas']} />
    
    const renderDate = (date) => {
        if (date) {
            const oDate = new Moment(date);
            return oDate.format('DD/MM/YYYY').toLowerCase();
        }

        return '';
    }

    const listArticles = () => {
        if (!articles || typeof articles != 'object' || articles.length == 0) 
            return <p>Aucun article disponible.</p>;

        return (
            <Carousel controls={false}>
            {articles.map((article) => { 
                console.log('actu', article);
                const backgroudImage = (article.media !== undefined && typeof article.media == 'string' && article.media.length > 0) ? 
                    SETTINGS.BASE_URL + 'uploads/medias/actuality/' + article.media :
                    defaultBackground;

                return (
                    <Carousel.Item key={article.id}>
                        <Link to={"/actualites/"+article.id} className="home-item">
                            <div className="background" style={{backgroundImage: `url(${backgroudImage})`}}></div>
                            <div className="overlay"></div>
                            <div className="ct-title">
                            <div className="active-title">
                                    <h2>Le {renderDate(article.date)}</h2>
                                    <p>{article.title}</p>
                                </div>
                                <div className="hover-title">
                                    <p>Lire l'article {arrowCircleRight}</p>
                                </div>
                            </div>
                        </Link>
                    </Carousel.Item>
                )
            })}
            </Carousel>
        );
    }

    return (
        <section id="home" className="container d-flex flex-row flex-wrap align-items-stretch align-content-stretch">
            <div className="flex-grow-5">
                <div className="home-item">
                    <Carousel controls={false}>
                        <Carousel.Item>
                            <div className="img" style={{backgroundImage: `url(${diapo})`}}></div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="img" style={{backgroundImage: `url(${diapo})`}}></div>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>
            <div className="flex-grow-3">
                <Link to="/qui-sommes-nous" className="home-item">
                    <div className="background" style={{backgroundImage: `url(${imgQuisommesnous})`}}></div>
                    <div className="overlay"></div>
                    <div className="ct-title">
                        <div className="active-title">
                            <div className="row">
                                <div className="col-6">
                                    <h2>Qui<br />sommes-nous ?</h2>
                                </div>
                                <div className="col-6">
                                    <p>Restaurant traditionnel Libannais à Rennes, situé rue Saint-malo</p>
                                </div>
                            </div>
                        </div>
                        <div className="hover-title">
                            <div className="row">
                                <div className="col-6">
                                    <h2>Qui<br />sommes-nous ?</h2>
                                </div>
                                <div className="col-6">
                                    <p>Découvrez notre équipe et l'esprit de notre restaurant Libannais {arrowCircleRight}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="flex-grow-2">
                <Link to="/la-carte" className="home-item">
                    <div className="background" style={{backgroundImage: `url(${imgCarte})`}}></div>
                    <div className="overlay"></div>
                    <div className="ct-title">
                        <div className="active-title">
                            <h2>La carte</h2>
                            <p>Nos plats et spécialités</p>
                        </div>
                        <div className="hover-title">
                            <h2>Lire la carte</h2>
                            <p>Et découvrez nos plats {arrowCircleRight}</p>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="flex-grow-2">
                <Link to="/a-emporter" className="home-item">
                    <div className="background" style={{backgroundImage: `url(${imgEmporter})`}}></div>
                    <div className="overlay"></div>
                    <div className="ct-title">
                        <div className="active-title">
                            <h2>À emporter</h2>
                            <p>Découvrez nos plats</p>
                        </div>
                        <div className="hover-title">
                            <h2>Chez vous</h2>
                            <p>Et dégustez-les à la maison {arrowCircleRight}</p>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="flex-grow-2">
                <Link to="/traiteur-libannais" className="home-item">
                    <div className="background" style={{backgroundImage: `url(${imgTraiteur})`}}></div>
                    <div className="overlay"></div>
                    <div className="ct-title">
                        <div className="active-title">
                            <h2>Traiteur</h2>
                            <p>Cuisine traditionnelle</p>
                        </div>
                        <div className="hover-title">
                            <h2>Consultez</h2>
                            <p>Notre service traiteur {arrowCircleRight}</p>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="flex-grow-2">
                <div className="actu-wrapper">
                    {/*
                    <Link to="/actualites" className="home-item">
                        <div className="background" style={{backgroundImage: `url(${imgActualite})`}}></div>
                        <div className="overlay"></div>
                        <div className="ct-title">
                            <div className="active-title">
                                <h2>Actualités</h2>
                                <p>Les dernières actualités du restaurant</p>
                            </div>
                            <div className="hover-title">
                                <h2>Lire Les articles</h2>
                                <p>Tenez-vous informé {arrowCircleRight}</p>
                            </div>
                        </div>
                    </Link>
                    */}
                    {(articles == undefined || articles == null) ?
                        <div className="container">
                            <div className="text-center p-2">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Chargement...</span>
                                </div>
                            </div>
                        </div> :
                        listArticles()
                    }
                    <div className="actu-title">Actus</div>
                </div>
            </div>
            <div id="home-flag"></div>
        </section>
    )
}

export default Home;