import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Navigation from './Components/Navbar'
import Content from './Layouts/Content'
import Footer from './Layouts/Footer'
import axios from 'axios'
import { SET_NEWS } from './Actions/ActionsTypes';
import SETTINGS from './constant';

function App(props) {
    const dispatch = useDispatch();
    const { articles } = useSelector(state => state.news);
    const navbarProps = {};

    useEffect(() => {
        if (articles === undefined || articles === null || typeof articles != 'object' || articles.length == 0) {
            const fetchData  = async() => {
                console.log('FETCH');
                const result = await axios(SETTINGS.BASE_URL + 'api/articles');
                dispatch({ type: SET_NEWS, payload: result.data })
            }

            fetchData();
        }
    }, []);

    return (
        <div className="App">
            <header className="App-header bg-dark">
                <div className="container">
                    <Navigation {...navbarProps}/>
                </div>    
            </header>

            <Content />

            <footer>
                <Footer />
            </footer>
        </div>
    );
}

export default App;
