import React, { useState, useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import Home from '../Pages/Home'
import Emporter from '../Pages/Emporter'
import Carte from '../Pages/Carte'
import Traiteur from '../Pages/Traiteur'
import Actualite from '../Pages/Actualite'
import Quisommesnous from '../Pages/Quisommesnous'
import Reservation from '../Pages/Reservation'
import Contact from '../Pages/Contact'
import Mentions from '../Pages/Mentions'
import Error from '../Pages/Error'

function Content(props) {
    return (
        <>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/qui-sommes-nous" component={Quisommesnous}/>
                <Route exact path="/la-carte/:category?" component={Carte}/>
                <Route path="/a-emporter" component={Emporter}/>
                <Route path="/traiteur-libannais" component={Traiteur}/>
                <Route exact path="/actualites/:id?" component={Actualite}/>
                <Route path="/reservation" component={Reservation}/>
                <Route path="/contact" component={Contact}/>
                <Route path="/mentions-legales" component={Mentions}/>
                <Route component={Error} />
            </Switch>
        </>
    )
}

export default Content;