import React from 'react';
import Menu from '../Components/Plats';
import bkg from '../Images/bkg-a-emporter.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons'

function Emporter(props) {
    const phone = <FontAwesomeIcon icon={faPhoneSquareAlt} mask={['fas']} />

    return (
        <section id="emporter" className="page">
            <div className="background-image" style={{backgroundImage: `url(${bkg})`}}></div>

            <div className="container">
                <div className="page-title">
                    <span className="topline">Du mardi au dimanche, midi - soir</span><br />
                    <span className="topline">Horaires selon affluence</span>
                    <h2>Nos plats à emporter</h2>
                    <a className="btn mt-3" id="reservation-btn" href="tel:+33299635191"> 
                        { phone }&nbsp;
                        Commander : <b>02.99.63.51.91</b>
                    </a>
                </div>

                <Menu />
            </div>
        </section>
    )
}

export default Emporter;