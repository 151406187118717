import { combineReducers } from "redux";
import NavbarReducer from './NavbarReducer';
import NewsReducer from './NewsReducer';
import { reducer as form } from 'redux-form';

const rootReducer = combineReducers({
    form,
    authentification: NavbarReducer,
    news: NewsReducer
});

export default rootReducer;